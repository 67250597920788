import firebase from 'firebase/compat/app'

import { RELATED_PLATFORM_SITE } from 'config/app-version'
import { AdvertisementCarousel } from 'types'

import { COLLECTION_ADVERTISEMENTS_CAROUSEL, db } from './firebase'

async function getAdvertisements(): Promise<AdvertisementCarousel[]> {
  const querySnapshot = await db
    .collection(COLLECTION_ADVERTISEMENTS_CAROUSEL)
    .where('apps', 'array-contains', RELATED_PLATFORM_SITE)
    .get()

  const queryDocuments = querySnapshot.docs

  return queryDocuments.map((doc: firebase.firestore.DocumentData) => {
    const item = doc.data() as AdvertisementCarousel

    return { ...item, key: doc.id }
  })
}

export const ApiServiceAdvertisementsCarousel = {
  getAdvertisements,
}
