import * as Sentry from '@sentry/browser'

import store from 'store'

const isSentryEnabled = process.env.REACT_APP_ENVIRONMENT === 'production'

/**
 * Initialize sentry only in cloud environments
 */
function initSentry(): void {
  if (isSentryEnabled) {
    Sentry.init({
      beforeSend(event, hint) {
        if (hint?.originalException === 'Timeout') {
          return null
        }

        return event
      },
      dsn: 'https://f3af6b7d46fb4498bc6f88208bbbc658@o383075.ingest.sentry.io/5212873',
      environment: 'production',
      ignoreErrors: [
        'Failed to get documents from server',
        'Failed to get document because the client is offline',
        "Cannot read property 'style' of null",
        'null is not an object (evaluating',
        'Network Error',
        'internal',
        'Missing or insufficient permissions',
        'Blocked a frame with origin "https://tiroganador.com" from accessing a cross-origin frame',
        'Forbidden',
        'TypeError: Load failed',
        'A network error (such as timeout, interrupted connection or unreachable host) has occurred.',
        'Loading chunk',
        'deadline-exceeded',
        'QuotaExceededError',
        "Cannot read properties of null (reading 'style')",
        'Firebase: The SMS verification code used to create the phone auth credential is invalid. Please resend the verification code sms and be sure use the verification code provided by the user.',
        "Unexpected token '<'",
        'Invalid credentials - phone:',
        'Firebase: Error (auth/argument-error)',
        'La conexión de red se perdió.',
        'Se agotó el tiempo de espera.',
        'UnknownError: Connection to Indexed Database server lost. Refresh the page to try again',
        'Proxy is not defined',
        'AbortError: Version change transaction was aborted in upgradeneeded event handler.',
        'Wr(...).getAll is not a function',
        'FirebaseError: Firebase: Error thrown when reading from storage.',
        'Firebase: A network AuthError (such as timeout, interrupted connection or unreachable host) has occurred. (auth/network-request-failed).',
        'FIRESTORE (9.8.1) INTERNAL ASSERTION FAILED: Unexpected state',
        'Firebase: We have blocked all requests from this device due to unusual activity. Try again later. (auth/too-many-requests).',
        'Non-Error promise rejection captured with value: Timeout',
        'Firebase: The SMS verification code used to create the phone auth credential is invalid. Please resend the verification code sms and be sure to use the verification code provided by the user. (auth/invalid-verification-code).',
        'Request failed with status code 500', // Dragon gaming
        'Request failed with status code 429', // Dragon gaming,
        'AbortError: The operation was aborted.',
        'Firebase: The phone verification request contains an invalid application verifier. The reCAPTCHA token response is either invalid or expired. (auth/invalid-app-credential).',
        'Firebase: Recaptcha verification failed - DUPE (auth/captcha-check-failed).',
        'FirebaseError: AppCheck: Fetch server returned an HTTP error status. HTTP status: 403.',
        'Firebase: Error (auth/error-code:-39).',
      ],
    })
  }
}

/**
 * Captures an exception event and sends it to Sentry if enabled, otherwise
 * logs the event in the browser console.
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function captureException(error: any, extra?: Record<string, unknown>): void {
  if (!isSentryEnabled) {
    // tslint:disable-next-line: no-console
    console.error(error)

    return
  }

  try {
    Sentry.withScope((scope) => {
      if (extra) {
        scope.setExtras(extra)
      }

      try {
        const user = store?.getState()?.user

        if (user) {
          scope.setExtra('userId', user.userId)
          scope.setExtra('appVersion', user.appVersion)
          scope.setExtra('name', user.name)
        }
      } catch (_) {}

      Sentry.captureException(error)
    })
  } catch (_) {}
}

function setUser(user: Sentry.User | null): void {
  try {
    Sentry.setUser(user)
  } catch (_) {}
}

export default {
  captureException,
  initSentry,
  setUser,
}
