import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'

import ReactDOM from 'react-dom/client'
import { StoreProvider } from 'easy-peasy'
import { PersistGate } from 'redux-persist/integration/react'

import { toast } from 'react-toastify'

import Sentry from 'utils/sentry'

import App from './app'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import store, { persistor } from './store'

// Initialize Sentry for Log Monitoring
Sentry.initSentry()

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <StoreProvider store={store}>
    <PersistGate
      loading={null}
      // @ts-ignore
      persistor={persistor}
    >
      <App />
    </PersistGate>
  </StoreProvider>,
)

serviceWorkerRegistration.register({
  onUpdate: async (registration: ServiceWorkerRegistration): Promise<void> => {
    // We want to run this code only if we detect a new service worker is
    // waiting to be activated.
    if (registration && registration.waiting) {
      await registration.unregister()

      // Once the service worker is unregistered, we can reload the page to let
      // the browser download a fresh copy of our app (invalidating the cache)
      try {
        toast(
          '🚀 ¡Hay una nueva versión del app disponible!. La página se actualizará en un momento.',
          {
            onClose: () => {
              window.location.reload()
            },
            autoClose: 5000,
            closeOnClick: true,
            position: 'top-center',
          },
        )
      } catch (error) {
        window.location.reload()
      }
    }
  },
})

// if (navigator) {
//   ;(async () => {
//     try {
//       const subscriptions = navigator.serviceWorker
//         .getRegistrations()
//         .then((r) => {
//           return r.map((sw) => {
//             if (
//               !(sw.active && sw.active.scriptURL.includes('firebase-messaging'))
//             ) {
//               return Promise.resolve()
//             }

//             return sw.pushManager.getSubscription()
//           })
//         })

//       console.log('subscriptions', subscriptions)

//       const arr = await subscriptions
//       console.log('arr', arr)
//       // @ts-ignore
//       if (arr?.[0] && arr[0]) {
//         console.log('arr[0]', arr[0])
//         // @ts-ignore
//         const subs = arr[0]

//         if (subs?.unsubscribe) {
//           const
//           console.log(
//             subs.unsubscribe().then((result: any) => {
//               console.log(result)
//             }),
//           )
//         }
//       }
//     } catch (error) {
//       Sentry.captureException(error)
//     }
//   })()
// }
