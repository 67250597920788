import firebase from 'firebase/compat/app'
import moment from 'moment-timezone'

import { timezone } from 'config/timezone'
import { ConfigCashInShare } from 'types'

import { COLLECTION_CONFIG_CASH_IN_SHARES, db } from './firebase'

moment.tz.setDefault(timezone)

async function findConfigs({
  week,
  year,
}: {
  week?: number
  year: number
}): Promise<ConfigCashInShare[]> {
  let itemsQuery: firebase.firestore.Query = db
    .collection(COLLECTION_CONFIG_CASH_IN_SHARES)
    .where('year', '==', year)
    .orderBy('week', 'desc')

  if (week) {
    itemsQuery = itemsQuery.where('week', '==', week)
  }

  const querySnapshot = await itemsQuery.get({ source: 'server' })
  if (querySnapshot.empty) {
    return []
  }

  const queryDocuments = querySnapshot.docs
  const data = queryDocuments.map(
    (doc: firebase.firestore.DocumentData): ConfigCashInShare => {
      const item = doc.data()

      return {
        ...item,
        key: doc.id,
      }
    },
  )

  return data
}

export const ApiServiceConfigCashInShares = {
  findConfigs,
}
