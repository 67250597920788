import { Shop, User } from 'types'
import Sentry from 'utils/sentry'

import { COLLECTION_USERS, cloudFunctions, db, firebaseApp } from './firebase'

async function addUser(user: Partial<User>): Promise<void> {
  // Get cloud function
  const cloudFunction = cloudFunctions.httpsCallable('client-user-addUser')

  // Call function
  const result = await cloudFunction(user)

  if (result.data?.error) {
    throw new Error(result.data.error)
  }
}

async function findShopFriend({
  friendNumber,
  shopKey,
}: {
  friendNumber: string
  shopKey: string
}): Promise<{ name: string; userId: string }> {
  // Get cloud function
  const cloudFunction = cloudFunctions.httpsCallable(
    'client-user-findShopFriend',
  )

  // Call function
  const result = await cloudFunction({
    friendNumber,
    shopKey,
  })

  if (result.data?.error) {
    throw new Error(result.data.error)
  }

  return result.data.friend
}

async function verifyPhoneNumber(id: string): Promise<{
  exists: boolean
  inactiveReason?: string
  isActive?: boolean
  isDeleted?: boolean
  temporalCode: boolean
  usesPermanentCode?: boolean
}> {
  // Get cloud function
  const cloudFunction = cloudFunctions.httpsCallable(
    'client-auth-verifyPhoneNumber',
    {
      timeout: 540000,
    },
  )

  // Call function
  const result = await cloudFunction({ id })

  if (result?.data?.error) {
    throw new Error(result.data.error)
  }

  return result.data
}

async function login(id: string, code: string): Promise<User> {
  // Get cloud function
  const loginFunction = cloudFunctions.httpsCallable('client-auth-login')

  const request: { id: string; code?: string } = {
    id,
  }

  if (code) {
    request.code = code
  }

  // Call function
  const result = await loginFunction(request)
  if (
    !result?.data ||
    result.data.error ||
    !result.data.token ||
    !result.data.user
  ) {
    if (result.data.error) {
      throw new Error(result.data.error)
    }

    throw new Error('Invalid credentials')
  }

  // Sign with custom token
  await firebaseApp.auth().signInWithCustomToken(result.data.token)

  // Return admin object
  return result.data.user
}

async function logout(): Promise<void> {
  Sentry.setUser(null)
  await firebaseApp.auth().signOut()
}

async function sendCashToFriend({
  amount,
  friendNumber,
  shopKey,
}: {
  amount: number
  friendNumber: string
  shopKey: string
}): Promise<void> {
  if (amount <= 0) {
    throw new Error('Invalid amount')
  }

  // Get cloud function
  const cloudFunction = cloudFunctions.httpsCallable(
    'client-user-sendCashToFriend',
  )

  // Call function
  const result = await cloudFunction({
    amount,
    friendNumber,
    shopKey,
  })

  if (result.data?.error) {
    throw new Error(result.data.error)
  }
}

async function reduceWalletProfit({
  amount,
  shopKey,
}: {
  amount: number
  shopKey: string
}): Promise<void> {
  if (amount <= 0) {
    throw new Error('Invalid amount')
  }

  // Get cloud function
  const cloudFunction = cloudFunctions.httpsCallable(
    'client-user-reduceWalletProfit',
  )

  // Call function
  const result = await cloudFunction({
    amount,
    shopKey,
  })

  if (result.data?.error) {
    throw new Error(result.data.error)
  }
}

async function updateWalletState(
  shopKey: string,
  status: boolean,
): Promise<void> {
  // Get cloud function
  const cloudFunction = cloudFunctions.httpsCallable(
    'client-user-updateWalletState',
  )

  // Call function
  const result = await cloudFunction({
    shopKey,
    status,
  })

  if (result.data?.error) {
    throw new Error(result.data.error)
  }
}

async function updateMe({
  appVersion,
  cashOutCode,
  relatedPlatformSite,
  temporalCode,
}: {
  appVersion?: string
  cashOutCode?: string
  relatedPlatformSite?: Shop['relatedPlatformSite']
  temporalCode?: string
}): Promise<void> {
  if (!appVersion && !relatedPlatformSite && !temporalCode && !cashOutCode) {
    return
  }

  // Get cloud function
  const cloudFunction = cloudFunctions.httpsCallable('client-user-updateMe', {
    timeout: 540000,
  })

  // Call function
  const result = await cloudFunction({
    ...(appVersion && { appVersion }),
    ...(cashOutCode && { cashOutCode }),
    ...(temporalCode && { temporalCode }),
    ...(relatedPlatformSite && { relatedPlatformSite }),
  })

  if (result?.data?.error) {
    throw new Error(result.data.error)
  }

  return result.data
}

// Subscriptions
function subscribeToUser(
  userKey: string,
  handleSnapshot: (user?: User) => void,
): () => void {
  return db
    .collection(COLLECTION_USERS)
    .doc(userKey)
    .onSnapshot(
      (doc): void => {
        try {
          const item = doc.data() as User
          if (!item) {
            return
          }

          handleSnapshot({
            ...item,
            key: doc.id,
          })
        } catch (error) {
          Sentry.captureException(error)
        }
      },
      (error) => {
        if (error) {
          Sentry.captureException(error)
        }
      },
    )
}

export const ApiServiceUsers = {
  addUser,
  auth: firebaseApp.auth(),
  findShopFriend,
  login,
  logout,
  reduceWalletProfit,
  sendCashToFriend,
  subscribeToUser,
  updateMe,
  updateWalletState,
  verifyPhoneNumber,
}
